@import '../../../styles/customMediaQueries.css';

.root {}

.pageRoot {
  padding-bottom: 90px;
  background-color: #fff;

  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}

.editAddress {
  display: inline-block;
  text-decoration-line: underline;
  font-weight: 400;
  text-align: left;
  margin: 0;
  padding: 0;
  color: var(--Black, #000);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  text-decoration-line: underline;
  margin-left: 8px;
}

.updateInfo {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  text-decoration-line: underline;
  color: #000000;
  margin-top: 0;
}

.loadingText {
  margin: 24px;
}

.deliveryMethodTitle {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 600;
}

.bottomText {
  font-family: Poppins;
  font-size: 15px;
  font-style: italic;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.20000000298023224px;
  text-align: center;

}

.deliveryMethodCard {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: #fff;
  cursor: pointer;

  &.selectedCard {
    border: 2px solid #000;
  }
}

.servicelevelName {
  font-size: 0.8rem;
  line-height: 1.1rem;
  font-weight: 600;
  margin: 0;
  padding: 0;
}

.price {
  font-size: 12px;
  margin: 0;
  padding: 0;
}

.duration_terms {
  font-size: 12px;
  color: rgb(46 40 37 / 1);
  margin: 0;
  padding: 0;
  font-family: Montserrat, sans-serif;
  font-weight: 300;
}

.methodImage {
  display: flex;
  gap: 8px;
  margin-bottom: 0.5rem;

  & img {
    width: 48px;
  }
}

.errorText {
  color: var(--colorFail);
  margin: 24px;
}

.sectionHero {
  position: relative;
  margin: 0 0 36px 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.heroHeight {
  @media (--viewportMedium) {
    height: calc(0.41 * 100vw);
    max-height: 50vh;
    overflow-y: hidden;
  }
}

.imageWrapperForSectionHero {
  composes: heroHeight;
  background-color: var(--colorGrey100);
  /* Loading BG color */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;
}

.actionBar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: var(--colorGrey100);
  background-color: var(--colorGrey700);
  z-index: 1;
  /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;

  border-radius: var(--borderRadius);
}

.actionBarForProductLayout {
  margin: 24px;
  width: calc(100% - 48px);

  @media (--viewportMedium) {
    margin: 24px 0 24px 0;
    width: 100%;
  }

  @media (--viewportLarge) {
    margin: 0 0 24px 0;
  }
}

.actionBarForHeroLayout {
  position: absolute;
  top: 13px;
  left: 13px;
  width: calc(100% - 26px);

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;
}

.ownListingText {
  composes: h5 from global;
  margin: 14px 12px 11px 24px;

  @media (--viewportMedium) {
    margin: 24px 12px 24px 24px;
  }
}

.ownListingTextPendingApproval {
  color: var(--colorAttention);
}

.closedListingText {
  composes: h4 from global;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.editListingLink {
  composes: h5 from global;
  flex-shrink: 0;
  margin: 0;
  padding: 14px 24px 11px 12px;
  color: var(--colorGrey100);

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--colorWhite);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 0;
    padding: 30px 24px 24px 12px;
  }
}

.editIcon {
  margin: -6px 7px 0 0;
}

.rootForImage {
  composes: heroHeight;
  width: 100%;
  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    object-fit: cover;
    border-radius: var(--borderRadius);

    &:hover {
      transform: scale(1.005);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.viewPhotos {
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 8px 13px 6px 13px;

  /* Colors */
  background-color: var(--colorWhite);

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--colorWhite);
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentWrapperForHeroLayout {
  @media (--viewportMedium) {
    width: 100%;
    max-width: var(--contentMaxWidth);
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    position: relative;
  }

  @media (--viewportLarge) {
    max-width: calc(var(--contentMaxWidth) + 72px);
    padding: 0 36px;
    margin: 0 auto 117px;
  }

  @media (--viewportXLarge) {
    max-width: var(--contentMaxWidth);
    padding: 0;
  }
}

.mainColumnForProductLayout {
  @media (--viewportMedium) {}

  @media (--viewportLarge) {
    /* contentContainer's full width - (orderColumn + paddings + margin) */
    max-width: calc(100% - 597px);
    flex-basis: calc(100% - 597px);
    flex-shrink: 0;
    flex-grow: 1;
  }

  @media (max-width: 1280px) {
    max-width: calc(100% - 441px);
    flex-basis: calc(100% - 441px);
  }

  @media (max-width: 1023px) {
    max-width: calc(100%);
    flex-basis: calc(100%);
  }
}

.orderColumnForProductLayout {
  @media (--viewportMedium) {}

  @media (--viewportLarge) {
    display: block;
    margin-left: 22px;
    flex-basis: 559px;
    flex-shrink: 0;
  }

  @media (max-width: 1280px) {
    flex-basis: 441px;
  }

  @media (max-width: 576px) {
    padding: 0 1rem;
  }
}

.contentWrapperForProductLayout {
  margin: 0 auto 56px auto;
  background-color: #fff;

  @media (--viewportMedium) {
    width: 100%;
    max-width: var(--contentMaxWidth);
    padding: 0 24px 0 24px;
    margin: 0 auto 56px auto;
  }

  @media (--viewportLarge) {
    display: flex;
    flex-direction: row;
    max-width: 1218px;

    padding: 12px 0px 0 0px;
    margin: 0 auto 117px auto;
  }

  @media (max-width: 1400px) {
    max-width: 1138px;
  }

  @media (max-width: 1280px) {
    max-width: 938px;
  }

  @media (max-width: 1023px) {
    max-width: 540px;
    padding: 0 0px 0 0px;
  }

  @media (max-width: 767px) {
    max-width: 430px;
    padding: 0 0px 0 0px;
  }
}

.mainColumnForHeroLayout {
  flex-basis: 100%;
  margin-bottom: 23px;

  @media (--viewportMedium) {
    margin-top: 50px;
    margin-bottom: 51px;
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    margin-top: 80px;
    margin-bottom: 0px;
    /* contentContainer's full width - (orderColumn + two paddings + margin + border) */
    max-width: calc(100% - 433px);
    flex-basis: calc(100% - 433px);
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.orderColumnForHeroLayout {
  @media (--viewportMedium) {}

  @media (--viewportLarge) {
    display: block;
    margin-top: 80px;
    margin-left: 60px;
    border-left: 1px solid var(--colorGrey100);
    padding-left: 60px;
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.orderPanel {

  /* Note: panel height might be too much on small viewport - hence min-height */
  @media (--viewportLarge) and (min-height: 950px) {
    position: sticky;
    top: 108px;
  }
}

.productOrderPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 0px;
  }

  /* Note: panel height might be too much on small viewport - hence min-height */
  @media (--viewportLarge) and (min-height: 950px) {
    position: sticky;
    top: 108px;
  }
}

.orderPanelTitle {
  /* Font */
  font-weight: var(--fontWeightBold);
  text-transform: capitalize;
  /* Layout */
  width: 100%;
  margin-top: 0;
  color: #374151;
  font-style: normal;
  font-weight: 700;
  /* font-family: Inter; */
  font-size: 1.5rem;
  line-height: 2rem;
  font-family: 'bon-vivant';
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-bottom: 29px;
    font-size: 33.188px;
    line-height: 40px;
    /* 120.527% */
  }

  &.orderPanelTitleDesktop {
    @media (max-width: 1023px) {
      display: none;
    }
  }
}

.sectionAvatar {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -31px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Flexbox would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    position: absolute;
    top: -49px;
    margin-left: 0;
    margin-top: 0;
  }
}

.avatarMobile {
  display: flex;

  @media (--viewportMedium) {
    display: none;
    /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none;
  /* Hide the large avatar from the smaller screens */

  @media (--viewportMedium) {
    display: flex;
  }
}

.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 1px;
  }
}

.desktopPerUnit {
  /* Font */
  composes: h5 from global;
  color: var(--colorGrey700);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  margin-bottom: 33px;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 0;
  }
}

.title {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--colorGrey700);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 2px;
  }
}

.author {
  width: 100%;
  composes: h5 from global;

  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--colorGrey700);
  }
}

.authorNameLink {
  composes: textSmall from global;
  color: var(--colorGrey700);
}

.contactWrapper {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  margin: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    line-height: 16px;
  }
}

.sectionText {
  padding: 0 24px;
  margin-bottom: 36px;
  display: none;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 40px;
  }
}

.sectionHeading,
.sectionHeadingWithExtraMargin {
  /* Font for sections titles */
  composes: p from global;
  padding: 0;
  font-weight: var(--fontWeightBold);

  margin-top: 0;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 8px;
  }
}

.sectionHeadingWithExtraMargin {
  @media (--viewportMedium) {
    margin-bottom: 16px;
  }
}

.text {
  font-weight: var(--fontWeightRegular);
  padding: 1px 0 5px 0;
  margin: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    padding: 3px 0 5px 0;
    margin: 0;
  }
}

.ingress {
  composes: p from global;
  margin: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.sectionDetails,
.sectionMultiEnum {
  padding: 0 24px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 40px;
  }
}

.sectionMap {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
  }
}

.sectionReviews,
.sectionAuthor {
  padding: 0 24px;
  margin-top: 10px;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    margin-top: 40px;
    padding: 8px 0;
  }
}

.details {
  padding-bottom: 1px;
  margin: 0;
}

.detailsRow {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  padding: 1px 0 4px 0;
  line-height: 36px;
  list-style: none;
  font-weight: var(--fontWeightRegular);

  &:not(:last-child) {
    border-bottom: 1px solid var(--colorGrey100);
  }

  @media (--viewportMedium) {
    padding: 5px 0 2px 0;
    line-height: 32px;
  }
}

.detailLabel {
  font-weight: var(--fontWeightSemiBold);
  letter-spacing: -0.09px;
}

.inquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.inquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.inquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--colorWhite);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }

  & button {
    background-color: #000;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: calc(100vh - 193px);
  width: 100%;

  /* Static map: dimensions are 640px */
  max-width: 640px;
  max-height: 640px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 75vh;
  }

  @media (--viewportLarge) {
    height: 417px;
    margin-bottom: 7px;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.productGallery {
  margin: 0 0 36px 0;

  @media (--viewportMedium) {
    margin: 24px 0 48px 0;
  }

  @media (--viewportLarge) {
    margin: 0 0 40px 0;
    padding-bottom: 4px;
  }
}

.mobileHeading {
  margin-top: 0.75rem;
  padding: 0 0px;

  @media (--viewportLarge) {
    display: none;
  }
}

.orginalPrice {
  color: rgb(55 65 81 / 1);
  font-size: 16px;
  font-weight: normal;
  text-decoration: line-through;
}

.priceContainerInCTA,
.priceContainer {
  /* Layout */
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 4px;
  flex-shrink: 0;
  margin-right: 0px;
  padding: 0px 0;
  font-weight: 600;
}

.priceContainerInCTA {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: unset;
}

.priceContainer {
  color: #374151;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  /* 155.556% */
  color: rgb(55 65 81 / 1);
  font-size: 16px;

  & p {
    margin: 0;
  }
}

.productDesktopHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

.productSideContent {}

.productBookingPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 24px;

    /* The calendar needs 312px */
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.makeAnOfferButton {
  width: 55%;

  & button {
    width: 100%;
    border-radius: 4px;
    border: 1px solid var(--Black, #000);
    display: flex;
    padding: 9px 0px 9px 0px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    cursor: pointer;

    @media (max-width: 1023px) {
      min-height: 38px;
      padding: 6px 0px 6px 0px;
    }
  }
}

.addToCartBtn {
  width: 45%;

  & button {
    width: 100%;
    border-radius: 4px;
    border: 1px solid var(--Black, #000);
    display: flex;
    padding: 9px 0px 9px 0px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    cursor: pointer;

    @media (max-width: 1023px) {
      min-height: 38px;
      padding: 6px 0px 6px 0px;
    }
  }
}

.offerForm {
  &>div {
    &>div {
      padding-left: 1.25rem !important;
      padding-right: 1.25rem !important;
      padding-top: 1rem !important;
      max-height: 580px;
      overflow-y: auto;

      &>button {
        padding-top: 1.5rem !important;

        & svg {
          width: 18px;
          height: 18px;
        }
      }
    }
  }

  & .makeOffer {
    & p {
      max-width: 434px;
      margin: 0 auto;
      text-align: center;
    }

    & button {
      display: flex;
      width: 268px;
      height: 34.791px;
      padding: 8px 0px 7.791px 0px;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      border-radius: 100px;
      background: var(--Black, #000);
      min-height: 34px;
      margin-top: 18px;
      margin: 18px auto 0;
    }
  }
}

.mobileContainer {
  display: none;

  @media (max-width: 1023px) {
    display: block;
  }

  @media (max-width: 576px) {
    padding: 0 1rem;
  }
}

.continueBtn {
  margin: 1rem auto 0;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width: 100%;
  min-height: 54px;
  border-radius: 50px;
}

.applyRemainingWrapper {
  width: 100%;
  max-width: 470px;
  margin: 0 auto;
}

.orginalPrice {
  color: rgb(55 65 81 / 1);
  font-size: 16px;
  font-weight: normal;
}

.priceContainerInCTA,
.priceContainer {
  /* Layout */
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 4px;
  flex-shrink: 0;
  margin-right: 0px;
  padding: 0px 0;
}

.priceContainerInCTA {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: unset;
}

.priceContainer {
  color: #374151;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  /* 155.556% */
  color: rgb(55 65 81 / 1);
  font-size: 16px;
  font-weight: normal;

  & p {
    margin: 0;
  }
}

.modalMyOfferContent {
  padding: 0 25px;
}

.modalTitle {
  color: var(--Black, #000);

  font-family: "Bon Vivant Family Serif";
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-bottom: 1px solid #BEBEBE;
  padding-bottom: 1rem;
  text-align: center;
}

.offerPriceNote {
  color: #383F52;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 125% */
}

.listedPrice {
  color: var(--Black, #000);
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
}

.submitOfferBtn {
  border-radius: 50px;

  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
}

.bottomText {
  margin-bottom: 20px;
}

.applyRemaining {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  gap: 12px 28px;
}

.radioBoxes {
  display: flex;
  gap: 19px;
}

.radioBox {
  display: flex;
  align-items: center;
  gap: 5px;
}

.deliveryMethodCardWrapper {
  display: flex;
  gap: 8px;
  margin-top: 10px;
  @media (max-width: 767px) {
    flex-direction: column;
  }
  &>div {
    padding: 12px;
    flex: 1;

    & img {
      max-height: 50px;
    }
  }
}

.paymentInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}