@import '../../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
}

.locationWrapper {
  margin-bottom: 40px;
}

.heading {
  composes: p from global;
  font-weight: var(--fontWeightBold);
  padding: 0;
  margin-bottom: 8px;
}
.editAddress {
  display: block;
  width: max-content;
  text-decoration-line: underline;
  font-weight: 400;
  text-align: left;
  margin: 0 auto;
  padding: 0;
  color: var(--Black, #000);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  text-decoration-line: underline;
  /* margin-left: 8px; */
  margin-bottom: 22px;
}
.locationDetails {
  padding: 0;
  margin: 0;
}

.card {
  composes: marketplaceInputStyles from global;

  @media (--viewportMedium) {
    padding: 11px 0 11px 16px;
  }
}

.cardError {
  border: var(--borderErrorField);
}

.error {
  color: var(--colorFail);
  font-size: 13px;
}

.errorMessage {
  margin-top: 24px;
  color: var(--colorFail);
}

.paymentMethodSelector {
  margin-bottom: 36px;

  @media (--viewportMedium) {
    margin-bottom: 40px;
  }
}

.saveForLaterUse {
  padding-top: 6px;
  margin-bottom: 36px;

  @media (--viewportMedium) {
    padding-top: 8px;
    margin-top: 0px;
    margin-bottom: 28px;
  }
}

.saveForLaterUseCheckbox {
  display: inline-block;
  padding: 2px 0 3px 0;

  @media (--viewportMedium) {
    padding: 1px 0 0px 0;
  }
}

.saveForLaterUseLabel {
  composes: h5 from global;

  color: var(--colorGrey700);
  margin-top: 3px;
  margin-bottom: 0px;
  cursor: pointer;
  margin-top: 0;
}

.saveForLaterUseLegalInfo {
  composes: h5 from global;

  color: var(--colorGrey300);
  display: inline-block;
  padding: 0px 0 0px 26px;
  margin-top: -2px;
  margin-bottom: 2px;

  @media (--viewportMedium) {
    margin-top: -1px;
    margin-bottom: 1px;
  }
}

.billingDetails {
  margin-bottom: 48px;
}

.sameAddressCheckbox {
  display: block;
  margin: 24px 0;
}

.messageLabel {
  margin: 0 0 5px 0;

  @media (--viewportMedium) {
    margin: 0 0 6px 0;
  }
}

.messageOptional {
  color: var(--colorGrey300);
}

.message {
  border-bottom-color: var(--colorGrey300);
}

.submitContainer {
  margin-top: auto;

  @media (--viewportLarge) {
    margin-top: 72px;
  }
}

.paymentInfo {
  composes: h5 from global;

  color: var(--colorGrey300);
  text-align: center;
  margin: 28px 0 0 0;

  @media (--viewportLarge) {
    font-size: 14px;
    padding: 2px 0 6px 0;
    margin: 24px 0 0 0;
  }
}

.submitButton {
  margin-top: 22px;

  @media (--viewportMedium) {
    margin-top: 26px;
  }
  @media (--viewportLarge) {
    margin-top: 16px;
  }
}

.missingStripeKey {
  color: var(--colorFail);
}

.modalContent {
}

.fieldBtn {
  margin-left: auto;
  margin-top: 1rem;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  width: max-content;
  min-height: auto;
}

.CheckoutModal {
  & > div {
    & > div {
      & > button {
        padding: 0 !important;
        right: unset !important;
        left: 18px !important;
        top: 16px;
        & svg {
          height: 18px;
          width: 18px;
        }
      }
      padding-top: 2.5rem !important;
      padding-bottom: 1.25rem !important;
      padding-left: 2.5rem !important;
      padding-right: 2.5rem !important;
      @media (min-width: 768px) {
        flex-basis: 448px !important;
      }
    }
  }
}

.buttonsGroup {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  & button {
    margin-left: 0;
  }
}

.paymentBoxWrapper {
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 25px 40px;
  margin-bottom: 32px;
  & input {
    border: 1px solid var(--Black, #000);
    padding: 3px 16px 3px 16px;
  }
  & select {
    padding: 3px 16px 3px 16px;
  }
  & h4 {
    color: #4a4a4a;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 133.333% */
    letter-spacing: -0.348px;
    margin: 0;
  }
}

.productDesktopHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

.productSideContent {
}

.productBookingPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 24px;

    /* The calendar needs 312px */
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.makeAnOfferButton {
  width: 55%;

  & button {
    width: 100%;
    border-radius: 4px;
    border: 1px solid var(--Black, #000);
    display: flex;
    padding: 9px 0px 9px 0px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    cursor: pointer;

    @media (max-width: 1023px) {
      min-height: 38px;
      padding: 6px 0px 6px 0px;
    }
  }
}

.addToCartBtn {
  width: 45%;

  & button {
    width: 100%;
    border-radius: 4px;
    border: 1px solid var(--Black, #000);
    display: flex;
    padding: 9px 0px 9px 0px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    cursor: pointer;

    @media (max-width: 1023px) {
      min-height: 38px;
      padding: 6px 0px 6px 0px;
    }
  }
}

.offerForm {
  & > div {
    & > div {
      padding-left: 1.25rem !important;
      padding-right: 1.25rem !important;
      padding-top: 1rem !important;
      max-height: 580px;
      overflow-y: auto;

      & > button {
        padding-top: 1.5rem !important;

        & svg {
          width: 18px;
          height: 18px;
        }
      }
    }
  }

  & .makeOffer {
    & p {
      max-width: 434px;
      margin: 0 auto;
      text-align: center;
    }

    & button {
      display: flex;
      width: 268px;
      height: 34.791px;
      padding: 8px 0px 7.791px 0px;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      border-radius: 100px;
      background: var(--Black, #000);
      min-height: 34px;
      margin-top: 18px;
      margin: 18px auto 0;
    }
  }
}

.mobileContainer {
  display: none;

  @media (max-width: 1023px) {
    display: block;
  }

  @media (max-width: 576px) {
    padding: 0 1rem;
  }
}

.continueBtn {
  margin: 1rem auto 0;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width: 100%;
  min-height: 54px;
  border-radius: 50px;
}

.applyRemainingWrapper {
  width: 100%;
  max-width: 470px;
  margin: 0 auto;
}

.orginalPrice {
  color: rgb(55 65 81 / 1);
  font-size: 16px;
  font-weight: normal;
}

.priceContainerInCTA,
.priceContainer {
  /* Layout */
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 4px;
  flex-shrink: 0;
  margin-right: 0px;
  padding: 0px 0;
}

.priceContainerInCTA {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: unset;
}

.priceContainer {
  color: #374151;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  /* 155.556% */
  color: rgb(55 65 81 / 1);
  font-size: 16px;
  font-weight: normal;

  & p {
    margin: 0;
  }
}

.modalMyOfferContent {
  padding: 0 25px;
}

.modalTitle {
  color: var(--Black, #000);

  font-family: 'Bon Vivant Family Serif';
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-bottom: 1px solid #bebebe;
  padding-bottom: 1rem;
  text-align: center;
}

.offerPriceNote {
  color: #383f52;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 125% */
}

.listedPrice {
  color: var(--Black, #000);
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
}

.submitOfferBtn {
  border-radius: 50px;

  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
}

.bottomText {
  margin-bottom: 20px;
}

.applyRemaining {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  gap: 12px 28px;
}

.radioBoxes {
  display: flex;
  gap: 19px;
}

.radioBox {
  display: flex;
  align-items: center;
  gap: 5px;
}

.deliveryMethodCardWrapper {
  display: flex;
  gap: 8px;
  margin-top: 10px;
  @media (max-width: 767px) {
    flex-direction: column;
  }
  & > div {
    padding: 12px;
    flex: 1;

    & img {
      max-height: 50px;
    }
  }
}

.paymentInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.deliveryMethodCard {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: #fff;
  cursor: pointer;

  &.selectedCard {
    border: 2px solid #000;
  }
}

.servicelevelName {
  font-size: 0.8rem;
  line-height: 1.1rem;
  font-weight: 600;
  margin: 0;
  padding: 0;
}

.price {
  font-size: 12px;
  margin: 0;
  padding: 0;
}

.duration_terms {
  font-size: 12px;
  color: rgb(46 40 37 / 1);
  margin: 0;
  padding: 0;
  font-family: Montserrat, sans-serif;
  font-weight: 300;
}

.methodImage {
  display: flex;
  gap: 8px;
  margin-bottom: 0.5rem;

  & img {
    width: 48px;
  }
}

.errorText {
  color: var(--colorFail);
  margin: 24px;
}

.updateInfo {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  text-decoration-line: underline;
  color: #000000;
  margin-top: 0;
}

.loadingText {
  margin: 24px;
}

.deliveryMethodTitle {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 600;
}

.bottomText {
  font-family: Poppins;
  font-size: 15px;
  font-style: italic;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.20000000298023224px;
  text-align: center;
}

.confirmDetails {
  max-width: 70%;
  margin: 0 auto;
  border-radius: 50px;
  padding-left: 10px;
  padding-right: 10px;
  @media (max-width: 767px) {
    max-width: 100%;
  }
}

.dflex {
  display: flex;
  margin-top: 20px;
  gap: 16px;
  @media (max-width: 767px) {
    flex-direction: column;
  }
  & h3 {
    margin-top: 13px;
  }
}

.saveForLaterUseLabelWithNoPadding{
  padding-left: 0;
}