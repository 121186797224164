@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
  & label {
    font-weight: 600;
    color: rgb(46 40 37 / 1);
    margin-bottom: 0.5rem;
    font-size: inherit;
    padding: 0;
  }
  & input {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 1rem;
    line-height: 1.5rem;
    border-color: rgb(0 0 0/ 1);
  }
  @media (max-width: 1023px) {
    margin-bottom: 0;
  }
}

.errorText{
  color: red;
}

.heading {
  composes: p from global;
  font-weight: var(--fontWeightBold);
  margin: 0 0 12px 0;

  padding-top: 4px;
  padding-bottom: 2px;

  @media (--viewportMedium) {
    margin: 0 0 0 0;
    padding-top: 5px;
    padding-bottom: 3px;
  }
}

.subTitle{
  margin-top: 5px;
  text-align: center;
}

.description{
  text-align: center;
  color: var(--Black, #000);
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 26px;
& b{
  font-weight: 500;
}
}

.rightContainer{
  margin-top: 1.25rem;
}



.locationBox {
  border:1px solid rgb(229 231 235 / 1);
  border-radius: 6px;
  margin-bottom: 1rem;
  & label {
    font-weight: 600;
    color: rgb(46 40 37 / 1);
    margin-bottom: 0.5rem;
    font-size: inherit;
    padding: 0;
  }
  & > div {
    border-bottom: 0;
    display: flex;
    align-items: center;
    padding-left: 16px;
    border-radius: 6px;
    &:before {
      position: relative;
      content: '';
      width: 18px;
      height: 18px;
      min-width: 18px;
      background-image: url("data:image/svg+xml,%3Csvg stroke='currentColor' fill='currentColor' stroke-width='0' viewBox='0 0 512 512' class='text-lg text-gray-400' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='none' stroke-linecap='round' stroke-linejoin='round' stroke-width='32' d='M256 48c-79.5 0-144 61.39-144 137 0 87 96 224.87 131.25 272.49a15.77 15.77 0 0025.5 0C304 409.89 400 272.07 400 185c0-75.61-64.5-137-144-137z'%3E%3C/path%3E%3Ccircle cx='256' cy='192' r='48' fill='none' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'%3E%3C/circle%3E%3C/svg%3E");
    }
    & svg {
      display: none;
    }
  }
  & input {
    height: 36px !important;
    border: 0 !important;
    &:focus {
      border: 0 !important;
      box-shadow: none;
      outline: none;
    }
  }
}

.formRow {
  /* This container uses flexbox layout */
  display: flex;
  justify-content: space-between;
  width: 100%;

  /* parent aka root is flexbox, this container takes all available space */
  flex-grow: 1;
  flex-shrink: 0;
  flex-wrap: wrap;

  @media (--viewportSmall) {
    flex-wrap: no-wrap;
  }
}

.field {
  width: 100%;
  margin-top: 24px;

  @media (--viewportSmall) {
    width: calc(50% - 12px);
  }
}

.fieldFullWidth {
  width: 100%;
  margin-top: 24px;
}

.coupons {
  font-family: 'bon-vivant';
  margin-top: 0;
  padding: 0;
  margin-bottom: 1.25rem;
  @media (min-width: 540px) {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

.couponField {
  & label {
    font-weight: 600;
    color: rgb(46 40 37 / 1);
    margin-bottom: 0.5rem;
    font-size: inherit;
    padding: 0;
  }
  & input {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 1rem;
    line-height: 1.5rem;
    border-color: rgb(0 0 0/ 1);
  }
}

.fieldBtn {
  margin-left: auto;
  margin-top: 1rem;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  width: max-content;
  min-height: auto;
}

.contentCenter {
  text-align: center;
  margin-top: 5rem;
  margin-bottom: 5rem;
  font-size: 1.25rem;
  & img {
    margin-bottom: 20px;
  }
}

.buttonsGroup {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  & button {
    margin-left: 0;
  }
}

.modalContent {
  text-align: center;
}

.modalTitle {
  margin: 0;
  padding: 0;
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-family: 'bon-vivant';
  border-bottom: 1px solid rgb(229 231 235 /1);
  padding-bottom: 1rem;
  text-align: center;
}

.editAddress{
  display: inline-block;
  text-decoration-line: underline;
  font-weight: 400;
  text-align: left;
  margin: 0;
  padding: 0;
  color: var(--Black, #000);
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 26px;
text-decoration-line: underline;
margin-left: 8px;
}

.nameContainer{
  display: flex;
  gap: 20px;
  margin-bottom: 1rem;
  & > div{
    flex: 1;
  }
}

.password{
  max-width: calc(50% - .5rem);
}

.saveChangesBtn{
  margin-top: 1rem;
    font-family: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    width: max-content;
    min-height: auto;
}

.phoneNumber{
  margin-top: 10px;
  display: block;
}

